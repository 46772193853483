export const USER_STATUS_EXIST = 'USER_STATUS_EXIST';
export const GET_SIRCLE = 'GET_SIRCLE';
export const GET_CITY = 'GET_CITY';
export const GET_AUTO_MARK =  'GET_AUTO_MARK';
export const GET_AUTO_MODEL =  'GET_AUTO_MODEL';
export const GET_MY_AUTO = 'GET_MY_AUTO';
export const GET_SERVICE_NAME = 'GET_SERVICE_NAME';
export const GET_USER_LINK = 'GET_USER_LINK';
export const GET_SCORE_DATA = 'GET_SCORE_DATA';

