import React, {Component} from "react";
import {connect} from "react-redux";
import { Table,Button, CheckPicker} from 'rsuite';
import  Arr from './arr';
import  ProductList from './productList'
import {TEST_POST} from "../config/Requsest";
import {Url} from "../config/Url";
import {Model} from "../../redux/action/actions";
let data = [
    {
        "label": "Eugenia",
        "value": "Eugenia",

    },
    {
        "label": "Kariane",
        "value": "Kariane",

    },
    {
        "label": "Karianesas",
        "value": "Karianesas",

    },
    {
        "label": "Karianea",
        "value": "Karianes",

    },

]



const { Column, HeaderCell, Cell, Pagination } = Table;
class AutoParts extends  Component {
    constructor(props) {
        super(props);
        this.state = {
            data: Arr
        };
    }
    Add_Score(e){
        e.preventDefault()
        let list = e.target.querySelector('.rs-picker-value-list');
        let data = new FormData(e.target)
        if (list != null){
            let lists = list.title.split(',');
            data.append('models', lists)
        }
        TEST_POST(Url.addproduct,data).then(res => {
            console.log(res)
        })


    }
    Show(e){

        let description = document.querySelector('.description');

        description.style.height = '100px';
        description.style.transform = 'scaleY(1)';
    }
    Close(e){
        let description = document.querySelector('.description');
        console.log()
        description.style.height = '0px';
        description.style.transform = 'scaleY(0)';

    }
    render() {
        console.log(this.props)
        return(
            <div className="auto_parts">
                <div className="auto_parts">
                    <form onSubmit={this.Add_Score} encType="multipart/form-data">
                        <div className="add_auto_container">
                            <select name="mark" id="" onChange={(e)=> {
                                this.props.dispatch(Model(e))
                            }}>

                                {this.props.auto.mark.map(auto => {
                                    return (
                                        <option
                                            key={auto.id}
                                            id={auto.id}
                                            value={auto.name}
                                            data-id={auto.id}
                                        >
                                            {auto.name}
                                        </option>
                                    )
                                })}
                            </select>
                            <select name="model" id="">
                                {this.props.auto.model.map(auto => {
                                    return (
                                        <option
                                            key={auto.id}
                                            id={auto.id}
                                            value={auto.name}
                                        >
                                            {auto.name}
                                        </option>
                                    )
                                })}
                            </select>
                            <div className="state">
                                <label className='state_label'>
                                    Նոր
                                    <input type="radio" name="state" value="Նոր" onClick={this.Close} />
                                </label>
                                <label className='state_label'>
                                    Օգտագործած
                                    <input type="radio" name="state" value="Օգտագործած" checked onClick={this.Show}/>
                                </label>
                                <label className='state_label'>
                                    Պատվերով
                                    <input type="checkbox" name="order" value="Պատվերով"/>
                                </label>
                                <label className="file">
                                    <input type="file" name="img[]" multiple/>
                                </label>
                            </div>


                            <textarea name="text" id="" placeholder="Նկարրագրություն․․․" className="description"></textarea>
                            <div className="add_auto_btn">
                                <Button type="submit" color="violet">Ավելացնել ավտոմեքենա․․․</Button>
                            </div>
                        </div>
                    </form>
                    <ProductList/>
                </div>

            </div>
        )
    }
}

const MakeStateToProps = (state) => {
    return state
}
const MainAutoParts= connect(MakeStateToProps)(AutoParts)

export default MainAutoParts;